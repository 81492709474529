import React, { useState, useEffect } from "react";
import { API_URL } from "../../constants/constants";
import api from "../../utils/axios";
import { toast } from "react-toastify";

import "./Feedback.css";

export default function Feedback() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`${API_URL}/api/v1/feedback/all`)
        .then((res) => {
          setFeedbacks(res.data);
          setLoading(false);
        })
        .catch((err) => {
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
          setLoading(false);
        });
    };

    getData();
  }, []);

  const getStars = (stars) => {
    return [...Array(5)].map((star, index) => {
      index += 1;
      return (
        <button key={index} className="star-button">
          {index <= stars ? (
            <i
              className="bi bi-star-fill"
              style={{ color: "rgb(255, 145, 0)" }}
            ></i>
          ) : (
            <i className="bi bi-star"></i>
          )}
        </button>
      );
    });
  };

  return (
    <div className="feedback">
      {/* <div className="title">Feedback</div> */}
      <div>
        {loading ? (
          <div className="mt-5 d-flex justify-content-center">
            <div className="dot-windmill"></div>
          </div>
        ) : (
          <div className="feedback-container">
            <table className="">
            <thead>
              <tr>
                <th>#</th>
                <th>User</th>
                <th>Communication</th>
                <th>Quality of work</th>
                <th>Response Time in chat</th>
                <th>Quick problem understanding</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {feedbacks?.map((feedback, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-center">{feedback?.user?.username}</td>
                    <td>{getStars(feedback?.communication)}</td>
                    <td>{getStars(feedback?.quality)}</td>
                    <td>{getStars(feedback?.response)}</td>
                    <td>{getStars(feedback?.understanding)}</td>
                    <td>{feedback?.comment}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </div>
        )}
      </div>
    </div>
  );
}

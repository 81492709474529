import React, { useState, useEffect } from "react";
import { API_URL, CURRENCY, ROLE } from "../../constants/constants";
import api from "../../utils/axios";
import moment from "moment";
import "../../assets/css/OnlineUser.css";
import { socket } from "../../context/socket";
import Badge from "react-bootstrap/Badge";
import { useNavigate } from "react-router-dom";
import { supportedLanguages } from "../../constants/languages";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

export default function OnlineUser() {
  const [users, setUsers] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [socketUsers, setSocketUsers] = useState({});
  // const [deviceInformation, setDeviceInformation] = useState({
  //     mobile: 0, tablet: 0, PC: 0, Other: 0
  // });
  const [loading, setLoading] = useState(true);
  const [isEditBalance, setIsEditBalance] = useState(Math.min());
  const [isEditLanguage, setIsEditLanguage] = useState(Math.min());
  const [translateLanguage, setTranslateLanguage] = useState(supportedLanguages[0].code);
  const [balance, setBalance] = useState("");
  const navigate = useNavigate();

  // Get client list for admin
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    socket.emit("online-status");
    socket.on("online-status-received", (data) => {
      const { users } = data;
      setSocketUsers(users);
      getUsers();
    });

    return () => {
      socket.removeListener("online-status-received");
    };
  }, []);

  useEffect(() => {
    let onlineUsers = [];
    let offlineUsers = [];
    for (let i = 0; i < users.length; i++) {
      if (isUserOnline(users[i]._id)) onlineUsers.push(users[i]);
      else offlineUsers.push(users[i]);
    }
    offlineUsers.sort((a, b) => {
      return new Date(b.lastseen).valueOf() - new Date(a.lastseen).valueOf();
    });
    let tempSortedUsers = onlineUsers.concat(offlineUsers);
    // setSortedUsers([...tempSortedUsers]);
    setSortedUsers(() => users);
    //   let mobile = 0, tablet = 0, PC = 0, O
    //   tempSortedUsers.forEach(item => {
    //           if (item.device === "Mobile") {
    //               mobile += 1;
    //           } else if (item.device === "Tablet") {
    //               tablet += 1;
    //           } else if (item.device === "PC") {
    //               PC += 1;
    //           } else {
    //               Other += 1;
    //           }
    //       }
    //   )
    // setDeviceInformation({
    //     mobile, tablet, PC, Other
    // });

  }, [users, socketUsers]);

  const isUserOnline = (userId) => {
    let isOnline = false;
    Object.values(socketUsers).forEach((value) => {
      if (value == userId) isOnline = true;
    });
    return isOnline;
  };

  const getUsers = async () => {
    await api
      .get(`${API_URL}/api/v1/user/all`)
      .then(async (res) => {
        const data = res.data;
        setUsers([...data]);
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onUpdateUserBalance = async (userId) => {
    await api
      .post(`${API_URL}/api/v1/user/clientbalance`, { userId, balance })
      .then((res) => {
        const data = res.data;
        const userData = [...sortedUsers];
        const index = userData.findIndex(item => item._id === data._id);
        userData[index].balance = data.balance;
        setSortedUsers([...userData]);
        setIsEditBalance(Math.min());
        socket.emit("update-user", { user: data });
      })
      .catch((err) => { setIsEditBalance(Math.min()) });
  };

  const onUpdateUserTranslate = async (userId) => {
    await api
      .post(`${API_URL}/api/v1/user/translatelanguagebyuserid`, { userId, translatelanguage: translateLanguage })
      .then((res) => {
        const data = res.data;
        const userData = [...sortedUsers];
        const index = userData.findIndex(item => item._id === data._id);
        userData[index].translatelanguage = data.translatelanguage;
        setSortedUsers([...userData]);
        setIsEditLanguage(Math.min());
        socket.emit("update-user", { user: data });
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        setIsEditLanguage(Math.min());
      });
  };

  const favouriteData = async (user) => {
    await api
      .post(`${API_URL}/api/v1/user/favourite`, {
        userId: user._id,
        isFavourite: user?.isFavourite ? false : true
      })
      .then((res) => {
        getUsers()
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  return (
    <div className="online-user">
      {/*<div className="online-user-title">User Device Summary</div>*/}
      {/*  <div className="d-flex flex-row">*/}
      {/*      <div className="pb-22 mx-2">*/}
      {/*          Mobile : {(deviceInformation.mobile * 100 / users.length).toFixed(2)} %*/}
      {/*      </div>*/}
      {/*      <div className="pb-2 mx-2">*/}
      {/*          Tablet : {(deviceInformation.tablet * 100 / users.length).toFixed(2)} %*/}
      {/*      </div>*/}
      {/*      <div className="pb-2 mx-2">*/}
      {/*          PC : {(deviceInformation.PC * 100 / users.length).toFixed(2)} %*/}
      {/*      </div>*/}
      {/*      <div className="pb-2 mx-2">*/}
      {/*          Others : {(deviceInformation.Other * 100 / users.length).toFixed(2)} %*/}
      {/*      </div>*/}
      {/*  </div>*/}
      {/* <div className="online-user-title">Online Users</div> */}
      <div>
        {loading ? (
          <div className="mt-5 d-flex justify-content-center">
            <div className="dot-windmill"></div>
          </div>
        ) : (
          isMobile ?
            <div className="online-user-container">
              <table className="">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Online Status</th>
                    <th>Last seen</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUsers.map((user, index) => (
                    <tr key={index}>
                      <td style={{ width: '30%' }}>{user.username}</td>
                      {isUserOnline(user._id) ? (
                        <>
                          <td>
                            <div className="online-user-status"></div>
                          </td>
                          <td></td>
                        </>
                      ) : (
                        <>
                          <td>
                            <div className="offline-user-status"></div>
                          </td>
                          <td>{moment(user.lastseen).fromNow()}</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            :
            <table >
              <thead>
                <tr>
                  <th>#</th>
                  <th></th>
                  <th>User Name</th>
                  <th>Role</th>
                  <th>Device Information</th>
                  <th>Online Status</th>
                  <th>Last seen</th>
                  <th>Balance</th>
                  <th>Language</th>
                  <th>To Estimate</th>
                  <th>To Confirm</th>
                  <th>To Todo</th>
                  <th>In Progress</th>
                  <th>Completed</th>
                  <th>Paid</th>
                </tr>
              </thead>
              <tbody>
                {sortedUsers.map((user, index) => (
                  <tr key={index}>
                    <td>{user._id}</td>
                    <td>
                      <div className="check-star cursor-pointer" onClick={() => favouriteData(user)}>
                        <i className={`${user?.isFavourite ? " bi bi-star-fill " : " bi bi-star "} `}></i>
                      </div>
                    </td>
                    <td>{user.username}</td>
                    <td>
                      {user.role === ROLE.ADMIN
                        ? "Administrator"
                        : user.role === ROLE.CLIENT
                          ? "Client"
                          : "Developer"}
                    </td>
                    <td>{user.deviceInformation}</td>
                    {isUserOnline(user._id) ? (
                      <>
                        <td>
                          <div className="online-user-status"></div>
                        </td>
                        <td></td>
                      </>
                    ) : (
                      <>
                        <td>
                          <div className="offline-user-status"></div>
                        </td>
                        <td>{moment(user.lastseen).fromNow()}</td>
                      </>
                    )}
                    {isEditBalance === index ? <td className="my-2">
                      <input className="w-75" type="number" value={Number(balance)} onChange={(e) => {
                        setBalance(Number(e.target.value))
                      }} />
                      <span className="mx-2" onClick={() => onUpdateUserBalance(user._id)}>
                        <i className="bi bi-check-lg"></i>
                      </span>
                      <span className="mx-2" onClick={() => { setIsEditBalance(Math.min()) }}>
                        <i className="bi bi-x-lg"></i>
                      </span>
                    </td> : <td onClick={() => { setIsEditBalance(index); setBalance(user.balance) }}>{user?.balance?.toFixed(2)} {user.currency == CURRENCY.USD ? "$" : "€"}</td>}
                    {isEditLanguage === index ?
                      <td className="my-2">
                        <select
                          className="name w-75"
                          value={translateLanguage}
                          onChange={event => setTranslateLanguage(event.target.value)}
                        >
                          {supportedLanguages.map((item, index) => {
                            return (
                              <option key={index} value={item.code}>
                                {item.language}
                              </option>
                            );
                          })}
                        </select>
                        <span className="mx-2" onClick={() => onUpdateUserTranslate(user._id)}>
                          <i className="bi bi-check-lg"></i>
                        </span>
                        <span className="mx-2" onClick={() => { setIsEditLanguage(Math.min()) }}>
                          <i className="bi bi-x-lg"></i>
                        </span>
                      </td>
                      : <td onClick={() => { setIsEditLanguage(index); setTranslateLanguage(user.translatelanguage ?? supportedLanguages[0].code) }}>{user.translatelanguage ?? ''}</td>}
                    <td>{user.toDoTaskCount} {(user.toDoRecentTaskCount !== 0 && user.toDoRecentTaskCount != null) && <Badge className="cursor-pointer" bg="success" onClick={() => navigate('/chat?clientId=' + user._id + '&status=0')}>+ {user.toDoRecentTaskCount}</Badge>}</td>
                    <td>{user.estimatedTaskCount} {(user.estimatedRecentTaskCount !== 0 && user.estimatedRecentTaskCount != null) && <Badge className="cursor-pointer" bg="success" onClick={() => navigate('/chat?clientId=' + user._id + '&status=1')}>+ {user.estimatedRecentTaskCount}</Badge>}</td>
                    <td>{user.confirmedTaskCount} {(user.confirmedRecentTaskCount !== 0 && user.confirmedRecentTaskCount != null) && <Badge className="cursor-pointer" bg="success" onClick={() => navigate('/chat?clientId=' + user._id + '&status=2')}>+ {user.confirmedRecentTaskCount}</Badge>}</td>
                    <td>{user.assignedTaskCount} {(user.assignedRecentTaskCount !== 0 && user.assignedRecentTaskCount != null) && <Badge className="cursor-pointer" bg="success" onClick={() => navigate('/chat?clientId=' + user._id + '&status=3')}>+ {user.assignedRecentTaskCount}</Badge>}</td>
                    <td>{user.completedTaskCount} {(user.completedRecentTaskCount !== 0 && user.completedRecentTaskCount != null) && <Badge className="cursor-pointer" bg="success" onClick={() => navigate('/chat?clientId=' + user._id + '&status=4')}>+ {user.completedRecentTaskCount}</Badge>}</td>
                    <td>{user.paidTaskCount} {(user.paidRecentTaskCount !== 0 && user.paidRecentTaskCount != null) && <Badge className="cursor-pointer" bg="success" onClick={() => navigate('/chat?clientId=' + user._id + '&status=5')}>+ {user.paidRecentTaskCount}</Badge>}</td>
                  </tr>
                ))}
              </tbody>
            </table>
        )}
      </div>
    </div>
  );
}

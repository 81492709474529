import React, { useState, useContext, useEffect, useRef } from "react";
import Avatar from "react-avatar";
import { SocketContext } from "../../context/socket";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addEstimate,
  addConfirm,
  addOthersUnread,
  addUnread,
  newMessage,
  addPay,
  addTask
} from "../../redux/taskSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Message from "./Message";
import User from "./User";
import { addUserToLocalStorage, getUserFromLocalStorage } from "../../utils/localStorage";
import api from "../../utils/axios";
import { toast } from "react-toastify";
import { API_URL, ROLE, ADMIN_NAME, LANGUAGE, FAST_SUPPORT, BUTTON_TYPE, STATUS, DEADLINE } from "../../constants/constants";
import moment from "moment";
import {
  setMessages,
  addMessage,
  deleteMessage,
  updateEditMessage,
  updateMessage
} from "../../redux/messageSlice";
import { changeBalance } from "../../redux/loginSlice";
import Images from "./Images";
import Files from "./Files";
import UserRead from "./UserRead";
import MessageTime from "./MessageTime";
import OneSignal from "react-onesignal";
import { addSocketEventListener } from "../../utils/socketCommon";
import { validURL } from "../../utils/common";
import VideoPlayer from "./VideoPlayer";
import ProvideAccessDetail from "./ProvideAccessDetail";
import Deposit from "../Billing/Deposit";
import { Modal } from "react-bootstrap"
import { decodeEntities } from "../../utils/common";
import { Button } from 'react-bootstrap'
import "../../assets/css/Version.css"
import ProvideLicenseKeyForm from "./ProvideLicenseKeyForm";
import ProvideURLForm from "./ProvideURLForm";
import {
  browserName,
  osName,
  deviceType,
  isMobile,
  isTablet,
} from "react-device-detect";

let selectedTask = {};
let position = 0;
let msg = "wp-sofa.chat ";

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

export default function MessageBox() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const task = useSelector((state) => state.current);
  const { tasks } = useSelector((state) => state.task);
  const [readStatus, setReadStatus] = useState([]);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState({});

  // user re-choose premium version
  const [isSelectedOnlyPremiumVersion, setIsSelectedOnlyPremiumVersion] = useState(false)

  // selected provide site
  const [isShowProvideSite, setIsShowProvideSite] = useState(false);

  // set chatbot data
  const [chatbotAccessDetailData, setChatbotAccessDetailData] = useState()

  const [modalShow, setModalShow] = useState(false);
  const [confettiStatus, setConfettiStatus] = useState(false);

  // const [isAdminOnline, setIsAdminOnline] = useState();

  const messagesEndRef = useRef(null);
  const messageBoxRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };
  // Set User
  useEffect(() => {
    const user = getUserFromLocalStorage();
    setUser(user);
  }, []);

  const messages = useSelector((state) => state.message);

  useEffect(() => {
    selectedTask = task;
  }, [task]);

  const isValidURL = (msg) => {
    const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
    return regex.test(msg);
  }

  // Socket Init
  useEffect(() => {
    addSocketEventListener(socket, "message-received", async (newMessageReceived) => {
      const user = getUserFromLocalStorage();
      if (
        newMessageReceived.sender._id != user._id &&
        (newMessageReceived.task._id != selectedTask._id || !selectedTask._id)
      ) {
        if (newMessageReceived.message) {
          const avatar = newMessageReceived.sender.avatar;
          // OneSignal.sendSelfNotification(
          //   /* Title (defaults if unset) */
          //   "New Message",
          //   /* Message (defaults if unset) */
          //   newMessageReceived.message
          // );

          if (!("Notification" in window)) {
          } else if (Notification.permission === "granted") {
            let notification = new Notification(`${newMessageReceived.sender.username} - ${newMessageReceived.task.taskname}`, {
              icon: validURL(avatar) ? avatar : `${API_URL}/avatar/${avatar}`,
              body: newMessageReceived.message,
            });
            notification.onclick = function () {
              window.open("chat");
            };
          }
        }
        if (newMessageReceived.message) {
          // msg = newMessageReceived.message + "   ";

          msg = `There is a new message! Task: ${newMessageReceived.task.taskname}  `;
          toast.success(`${newMessageReceived.message}`, { theme: "colored" });
        } else if (newMessageReceived.images.length > 0)
          toast.success("Image sent");
        else toast.success("File sent");
      }

      // await api
      //   .post(`${API_URL}/api/v1/task/latestMessage`, {
      //     taskId: newMessageReceived.task._<id,
      //     latestMessage: newMessageReceived._id,
      //   })
      //   .then((res) => {
      dispatch(
        newMessage({
          taskId: newMessageReceived.task._id,
          message: newMessageReceived.message,
        })
      );
      // })
      // .catch((err) => {
      //   if (err.response.status == 401) {
      //     navigate("/");
      //   }
      //   const errors = err.response.data.errors;
      //   errors.forEach((error) => toast.error(error.msg));
      // });

      const taskId = newMessageReceived.task._id;
      const userId = user._id;
      const senderId = newMessageReceived.sender._id;
      dispatch(addOthersUnread({ taskId, userId, senderId }));

      if (
        !selectedTask._id ||
        selectedTask._id != newMessageReceived.task._id
      ) {
        // await api
        //   .post(`${API_URL}/api/v1/task/unread`, {
        //     taskId: newMessageReceived.task._id,
        //   })
        //   .then((res) => {
        //     dispatch(addUnread({ task: newMessageReceived.task, user: user }));
        //   })
        //   .catch((err) => {
        //     if (err.response.status == 401) {
        //       navigate("/");
        //     }
        //     const errors = err.response.data.errors;
        //     errors.forEach((error) => toast.error(error.msg));
        //   });
        dispatch(addUnread({ task: newMessageReceived.task, user: user }));
      } else {
        // setMessages((messages) => [...messages, newMessageReceived]);
        dispatch(addMessage(newMessageReceived));
        socket.emit("message-read", {
          task: newMessageReceived.task,
          userId: user._id,
        });
      }
    });

    addSocketEventListener(socket, "message-deleted", async (deleteMessageReceived) => {
      if (deleteMessageReceived.task._id == selectedTask._id)
        dispatch(deleteMessage(deleteMessageReceived._id));
    });

    addSocketEventListener(socket, "message-edited", async (editMessageReceived) => {
      if (editMessageReceived.task._id == selectedTask._id)
        dispatch(
          updateEditMessage({
            id: editMessageReceived._id,
            message: editMessageReceived.message,
          })
        );
    });

    return () => {
      socket.removeAllListeners("message-received");
      socket.removeAllListeners("message-deleted");
      socket.removeAllListeners("message-edited");
    };
  }, []);

  // Reload message when task id is changed
  useEffect(() => {
    async function fetchData() {
      dispatch(updateMessage({}));
      dispatch(setMessages([]));
      await api
        .get(`${API_URL}/api/v1/message/all/${task._id}`)
        .then((res) => {
          const messages = res.data;
          dispatch(setMessages(messages));
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }

          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });

      setTimeout(() => socket.emit("join-chat", user._id), 1000);
      addSocketEventListener(socket, "updated-user", (user) => {
        let currentUser = getUserFromLocalStorage();
        currentUser.balance = user.balance;
        currentUser.translatelanguage = user.translatelanguage;
        addUserToLocalStorage(currentUser);
      })
    }

    fetchData();

    const tempReadStatus = task.unread;
    setReadStatus([...tempReadStatus]);

    const tempUsers = {};
    task.users.forEach((user) => {
      tempUsers[user._id] = user.avatar;
      setUsers(tempUsers);
    });
  }, [task._id, task.users]);

  // This is for animated title effect
  useEffect(() => {
    scrollTitle();
  }, []);

  const delaytimeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const scrollTitle = () => {
    if (msg != "wp-sofa.chat ") {
      document.title =
        msg.substring(position, msg.length) + msg.substring(0, position);
      position++;
      if (position > msg.length) position = 0;
    }
    window.setTimeout(() => scrollTitle(), 170);
  };

  // Scroll go to bottom
  useEffect(() => {
    const goToBottom = async () => {
      await delaytimeout(1000);
      scrollToBottom();
    };
    goToBottom();

    // messageBoxRef.current.scrollToBottom = messageBoxRef.current.scrollHeight;
  }, [messages]);

  const handleVersion = async (version) => {
    const user = getUserFromLocalStorage();
    if (chatbotAccessDetailData?.isSelectedVersion || user.role !== ROLE.CLIENT) return;

    switch (version) {
      case 'premium':
        socket.emit("handle-version", { task: task, userId: user._id, type: "version_premium" })
        break;
      case 'free':
        socket.emit("handle-version", { task: task, userId: user._id, type: "version_free" })
        break;
      default:
        break;
    }

    await api.post(`${API_URL}/api/v1/chatbot/is-selected-version`, {
      taskId: task._id
    })

    await api.post(`${API_URL}/api/v1/chatbot/selected-version`, {
      taskId: task._id,
      version: version
    })
  }

  const handleChooseOnlyPremiumVersion = async () => {
    if (chatbotAccessDetailData?.isReSelectedPremium) return;
    await api.post(`${API_URL}/api/v1/chatbot/re-selected-premium-version`, {
      taskId: task._id,
    })
    // if (!isAdminOnline) socket.emit("handle-version", { task: task, userId: user._id, type: "version_premium_&_admin_offline" })
    socket.emit("handle-version", { task: task, userId: user._id, type: "version_premium" })
    setIsSelectedOnlyPremiumVersion(true);
  }

  const [costTasksConfirmed, setCostTasksConfirmed] = useState(0);

  useEffect(() => {
    const tasksConfirmed = tasks.filter((task) => task.status === STATUS.CONFIRMED)
    const totalCost = tasksConfirmed.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.cost;
    }, 0);
    setCostTasksConfirmed(totalCost)
  }, [tasks])

  const onPay = async (cost) => {
    const user = getUserFromLocalStorage();
    const balance = user.balance;

    if (
      (balance - (costTasksConfirmed + cost) < 0)
    ) {
      setModalShow(true)
      toast.warning(
        `Hi ${user.username}, plz add money to your account (billing) to use our services...`
      );
    } else {
      const taskId = task._id;

      // await api.post(`${API_URL}/api/v1/chatbot/selected-fast-support`, {
      //   taskId: task._id
      // })

      // await api.post(`${API_URL}/api/v1/task/paid-fast-support`, {
      //   taskId: task._id
      // })

      await api
        .post(`${API_URL}/api/v1/task/payFastSupport`, {
          taskId,
          cost,
        })
        .then(async (res) => {
          const user = getUserFromLocalStorage();
          dispatch(changeBalance(-1 * cost));
          user.balance += -1 * cost;
          addUserToLocalStorage(user);
          socket.emit("new-pay-fast-support", { cost, task });
          socket.emit("handle-selected-fast-support", { task: task, userId: user._id, type: "selected_fast_support" })

          // if (cost === 50) socket.emit("handle-fast-support", { task: task, userId: user._id, type: FAST_SUPPORT.WITHIN_5HOUR })
          // if (cost === 100) socket.emit("handle-fast-support", { task: task, userId: user._id, type: FAST_SUPPORT.WITHIN_1HOUR })

          setConfettiStatus(true);
          setTimeout(() => {
            setConfettiStatus(false);
          }, 5000);

          let message;

          if (task.client.translatelanguage) {
            await api
              .post(`${API_URL}/api/v1/message/translate/`, {
                message: `Hi ${ADMIN_NAME}, I just paid fast support for the task.`,
                language: task.client.translatelanguage,
              })
              .then((res) => {
                message = decodeEntities(
                  res.data.data.translations[0].translatedText
                );
              });
          } else {
            if (task.client.language == LANGUAGE.EN) {
              message = `Hi ${ADMIN_NAME}, I just paid fast support for the task.`;
            } else if (task.client.language == LANGUAGE.DE)
              message = `Hallo ${ADMIN_NAME}, Ich habe gerade den schnellen Support für die Aufgabe bezahlt.`;
            else if (task.client.language == LANGUAGE.ES)
              message = `Hi ${ADMIN_NAME}, I just paid fast support for the task.`;
          }

          // sendMessage(message);
        })
        .catch((err) => {
          // if (err.response.status == 401) {
          //   navigate("/");
          // }
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    }
  };

  const handleSelectedFastSupport = () => {
    const user = getUserFromLocalStorage();
    if (chatbotAccessDetailData?.selectedFastSupport || user.role !== ROLE.CLIENT) return;
    onPay(5, "fast-support");
  }

  useEffect(() => {
    const fetchChatbotAccessDetailData = async () => {
      await api.get(`${API_URL}/api/v1/chatbot/${task._id}`)
        .then((res) => {
          const data = res?.data?.accessDetail;
          setChatbotAccessDetailData(data);
        })
    }
    fetchChatbotAccessDetailData();
  }, [messages])

  const [isShowProvideURLModal, setIsShowProvideURLModal] = useState(false);

  const handleProvideURL = async (answer) => {
    if (chatbotAccessDetailData?.selectedProvidedURL) return;
    if (answer === "yes" && user.role === ROLE.CLIENT) setIsShowProvideURLModal(true)
    if (answer === "no" && user.role === ROLE.CLIENT) {
      try {
        await api.post(`${API_URL}/api/v1/chatbot/without-provide-url`, {
          taskId: task._id
        })
          .then((res) => {
            socket.emit("handle-provide-url", { task: task, userId: user._id, type: "provide_url-no" })
          })
          .catch(err => {
            toast.error("Error saving access details");
          })
      } catch (err) {
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      }
    }
  }

  const handleRequestUrl = (answer) => {
    if (user.role !== ROLE.CLIENT || chatbotAccessDetailData?.selectedProvidedURL) return;
    if (answer === "yes") setIsShowProvideURLModal(true)
    if (answer === "no") {

    }
  }

  const handleProvideSiteAccess = async (answer) => {
    if (chatbotAccessDetailData?.isSelectedProvideAccessDetail) return;
    if (answer === "yes" && user.role === ROLE.CLIENT) setIsShowProvideSite(true)
    if (answer === "no" && user.role === ROLE.CLIENT) {
      try {
        await api.post(`${API_URL}/api/v1/chatbot/without-provide-access-detail`, {
          taskId: task._id
        })
          .then((res) => {
            socket.emit("handle-provide-site", { task: task, userId: user._id, type: "handle_provide_site--yes" })
          })
          .catch(err => {
            toast.error("Error saving access details");
          })
      } catch (err) {
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      }
    }
  }

  const handleRequestProvideData = async (answer) => {
    if (user.role !== ROLE.CLIENT || chatbotAccessDetailData?.isSelectedProvideAccessDetail) return;
    if (answer === "yes") {
      setIsShowProvideSite(true);
    }

    if (answer === "no") {
      await api.post(`${API_URL}/api/v1/chatbot/without-provide-access-detail`, {
        taskId: task._id
      })
        .then((res) => {
          socket.emit("handle-provide-site", { task: task, userId: user._id, type: "request_provide_data-no" })
        })
        .catch(err => {
          toast.error("Error saving access details");
        })
    }
  }

  const handleSelectedMaintainServiceOption = async (option) => {
    const user = getUserFromLocalStorage();
    if (chatbotAccessDetailData?.isSelectedMaintainService || user.role !== ROLE.CLIENT) return;

    switch (option) {
      case 'yes':
        // onPay(59, "maintain");
        socket.emit("handle-choose-maintain-service", { task: task, userId: user._id, type: "maintain_service--yes" })
        break;
      case 'no':
        socket.emit("handle-choose-maintain-service", { task: task, userId: user._id, type: "maintain_service--no" })
        break;
      default:
        break;
    }

    await api.post(`${API_URL}/api/v1/chatbot/is-selected-maintain-service`, {
      taskId: task._id,
      option
    })
  }

  return (
    <div className="flex-auto messagebox">
      <div className="p-2" ref={messageBoxRef}>
        {
          <Modal
            show={modalShow}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="p-3">
              <Deposit amount={5} modalShow={modalShow} setModalShow={setModalShow} setConfettiStatus={setConfettiStatus} />
            </div>
          </Modal>}
        {
          !isMobile ?
            messages &&
            messages.map((message, index) => {
              return (
                <div key={index}>
                  {/* {(index == 0 ||
              messages[index - 1].sender._id != message.sender._id) &&
            message.sender._id != user._id ? (
              <User
                avatar={message.sender.avatar}
                user={message.sender.username}
              />
            ) : (
              <></>
            )} */}
                  {(index == 0 ||
                    moment(messages[index - 1].createdAt).format("MMM Do YY") !=
                    moment(messages[index].createdAt).format("MMM Do YY")) && (
                      <div className="d-flex justify-content-between m-2">
                        <div className="w-100">
                          <hr />
                        </div>
                        <div className="text-center" style={{ width: "400px" }}>
                          {moment(message.createdAt).format("LL")}
                        </div>
                        <div className="w-100">
                          <hr />
                        </div>
                      </div>
                    )}
                  {(index == 0 ||
                    messages[index - 1].sender._id != message.sender._id ||
                    new Date(message.createdAt) -
                    new Date(messages[index - 1].createdAt) >
                    300000) &&
                    message.sender._id != user._id ? (
                    <User
                      avatar={message.sender.avatar}
                      user={message.sender.username}
                    />
                  ) : (
                    <></>
                  )}
                  {index == 0 ||
                    messages[index - 1].sender._id != message.sender._id ||
                    new Date(message.createdAt) -
                    new Date(messages[index - 1].createdAt) >
                    300000 ? (
                    <MessageTime
                      time={new Date(message.createdAt).toLocaleTimeString()}
                      type={
                        message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {message.message ? (
                    <Message
                      id={message._id}
                      message={message.message}
                      type={
                        message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                      }
                      deleted={message.message_deleted}
                      replied={message.replied}
                      edited={message.edited}
                      replymessage={
                        message.replied
                          ? message.replymessage
                          : {
                            id: "",
                            message: "",
                          }
                      }
                      createdAt={message.createdAt}
                      task={task}
                    />
                  ) : (
                    <></>
                  )}

                  {message.images.length > 0 ? (
                    <Images
                      message={message}
                      type={
                        message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {message.files && message.files[0]?.filename !== "youTube" && message.files.length > 0 ? (
                    <Files
                      message={message}
                      type={
                        message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {message.files && message.files[0]?.filename === "youTube" && message.files.length > 0 ? (
                    <>
                      <VideoPlayer
                        message={message}
                        type={
                          message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                        } />
                    </>
                  ) : (
                    <></>
                  )}

                  {
                    message.buttons && message.buttons.buttonType === "version_button" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""} `}>
                        <Button
                          className={`${chatbotAccessDetailData?.selectedVersion === "premium" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleVersion("premium")}>
                          Premium
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.selectedVersion === "free" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleVersion("free")}>
                          Free
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === "premium_button" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""}`}>
                        <div className="d-flex justify-content-start gap-1 m-1">
                          <Button
                            onClick={handleChooseOnlyPremiumVersion}
                            className={`${chatbotAccessDetailData?.isReSelectedPremium ? 'versionButtonClick' : 'versionButton'} ${isSelectedOnlyPremiumVersion && 'disableVersion'}`}
                          >
                            Premium
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === "url_button" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""} `}>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedURL === "yes" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleProvideURL("yes")}>
                          Provide URL
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedURL === "no" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleProvideURL("no")}>
                          I need support without sharing URL
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === BUTTON_TYPE.PROVIDE_SITE_BUTTON ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""}`}>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedAccessDetail === "yes" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleProvideSiteAccess("yes")}
                        >
                          Provide access details
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedAccessDetail === "no" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleProvideSiteAccess("no")}
                        >
                          I need support without sharing site access
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === "request_provide_data" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""}`}>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedAccessDetail === "yes" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleRequestProvideData("yes")}
                        >
                          Provide access details
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedAccessDetail === "no" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleRequestProvideData("no")}
                        >
                          I need support without sharing site access
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === "selected-fast-support" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""}`}>
                        <Button
                          onClick={() => handleSelectedFastSupport()}
                          className={`${chatbotAccessDetailData?.selectedFastSupport ? 'versionButtonClick' : 'versionButton'}`} >Proceed with expedited support ($5)
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {/* maintain service button */}
                  {
                    message.buttons && message.buttons.buttonType === "maintain-service-button" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""} `}>
                        <Button
                          className={`${chatbotAccessDetailData?.isSelectedMaintainService === "yes" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleSelectedMaintainServiceOption("yes")}>
                          Yes, I want to use the monthly maintenance service
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.isSelectedMaintainService === "no" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleSelectedMaintainServiceOption("no")}>
                          No i dont
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  <div className="d-flex justify-content-end">
                    {task.unread.map((unread) =>
                      user._id != unread.user &&
                      index == messages.length - unread.number - 1 &&
                      <UserRead key={unread?.user} image={users[unread.user]} />
                    )}
                  </div>
                </div>
              );
            })
            :
            // messages &&
            // messages.map((message, index) => {
            //   return (
            //     <div key={index}>
            //       {(index == 0 ||
            //         moment(messages[index - 1].createdAt).format("MMM Do YY") !=
            //         moment(messages[index].createdAt).format("MMM Do YY")) && (
            //           <div className="d-flex justify-content-between m-2">
            //             <div className="w-100">
            //               <hr />
            //             </div>
            //             <div className="text-center" style={{ width: "400px" }}>
            //               {moment(message.createdAt).format("LL")}
            //             </div>
            //             <div className="w-100">
            //               <hr />
            //             </div>
            //           </div>
            //         )}
            //       {(index == 0 ||
            //         messages[index - 1].sender._id != message.sender._id) &&
            //         message.sender._id != user._id ? (
            //         <User
            //           avatar={message.sender.avatar}
            //           user={message.sender.username}
            //         />
            //       ) : (
            //         <></>
            //       )}
            //       {/* {(index == 0 ||
            //     messages[index - 1].sender._id != message.sender._id ||
            //     new Date(message.createdAt) -
            //       new Date(messages[index - 1].createdAt) >
            //       300000) &&
            //   message.sender._id != user._id ? (
            //     <User
            //       avatar={message.sender.avatar}
            //       user={message.sender.username}
            //     />
            //   ) : (
            //     <></>
            //   )} */}
            //       {index == 0 ||
            //         messages[index - 1].sender._id != message.sender._id ||
            //         new Date(message.createdAt) -
            //         new Date(messages[index - 1].createdAt) >
            //         300000 ? (
            //         <MessageTime
            //           time={new Date(message.createdAt).toLocaleTimeString()}
            //           type={
            //             message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
            //           }
            //         />
            //       ) : (
            //         <></>
            //       )}
            //       {message.message ? (
            //         <Message
            //           id={message._id}
            //           message={message.message}
            //           type={
            //             message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
            //           }
            //           deleted={message.message_deleted}
            //           replied={message.replied}
            //           edited={message.edited}
            //           replymessage={
            //             message.replied
            //               ? message.replymessage
            //               : {
            //                 id: "",
            //                 message: "",
            //               }
            //           }
            //         />
            //       ) : (
            //         <></>
            //       )}

            //       {message.images.length > 0 ? (
            //         <Images
            //           // images={message.images}
            //           message={message}
            //           type={
            //             message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
            //           }
            //         />
            //       ) : (
            //         <></>
            //       )}
            //       {message.files.length > 0 ? (
            //         <Files
            //           key={message._id}
            //           files={message.files}
            //           type={
            //             message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
            //           }
            //         />
            //       ) : (
            //         <></>
            //       )}
            //       <div className="d-flex justify-content-end">
            //         {task.unread.map((unread) =>
            //           user._id != unread.user &&
            //             index == messages.length - unread.number - 1 ? (
            //             <UserRead image={users[unread.user]} />
            //           ) : (
            //             <></>
            //           )
            //         )}
            //       </div>
            //     </div>
            //   );
            // })
            messages &&
            messages.map((message, index) => {
              return (
                <div key={index}>
                  {/* {(index == 0 ||
              messages[index - 1].sender._id != message.sender._id) &&
            message.sender._id != user._id ? (
              <User
                avatar={message.sender.avatar}
                user={message.sender.username}
              />
            ) : (
              <></>
            )} */}
                  {(index == 0 ||
                    moment(messages[index - 1].createdAt).format("MMM Do YY") !=
                    moment(messages[index].createdAt).format("MMM Do YY")) && (
                      <div className="d-flex justify-content-between m-2">
                        <div className="w-100">
                          <hr />
                        </div>
                        <div className="text-center" style={{ width: "400px" }}>
                          {moment(message.createdAt).format("LL")}
                        </div>
                        <div className="w-100">
                          <hr />
                        </div>
                      </div>
                    )}
                  {(index == 0 ||
                    messages[index - 1].sender._id != message.sender._id ||
                    new Date(message.createdAt) -
                    new Date(messages[index - 1].createdAt) >
                    300000) &&
                    message.sender._id != user._id ? (
                    <User
                      avatar={message.sender.avatar}
                      user={message.sender.username}
                    />
                  ) : (
                    <></>
                  )}
                  {index == 0 ||
                    messages[index - 1].sender._id != message.sender._id ||
                    new Date(message.createdAt) -
                    new Date(messages[index - 1].createdAt) >
                    300000 ? (
                    <MessageTime
                      time={new Date(message.createdAt).toLocaleTimeString()}
                      type={
                        message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {message.message ? (
                    <Message
                      id={message._id}
                      message={message.message}
                      type={
                        message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                      }
                      deleted={message.message_deleted}
                      replied={message.replied}
                      edited={message.edited}
                      replymessage={
                        message.replied
                          ? message.replymessage
                          : {
                            id: "",
                            message: "",
                          }
                      }
                      createdAt={message.createdAt}
                      task={task}
                    />
                  ) : (
                    <></>
                  )}

                  {message.images.length > 0 ? (
                    <Images
                      message={message}
                      type={
                        message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {message.files && message.files[0]?.filename !== "youTube" && message.files.length > 0 ? (
                    <Files
                      message={message}
                      type={
                        message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {message.files && message.files[0]?.filename === "youTube" && message.files.length > 0 ? (
                    <>
                      <VideoPlayer
                        message={message}
                        type={
                          message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                        } />
                    </>
                  ) : (
                    <></>
                  )}

                  {
                    message.buttons && message.buttons.buttonType === "version_button" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""} `}>
                        <Button
                          className={`${chatbotAccessDetailData?.selectedVersion === "premium" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleVersion("premium")}>
                          Premium
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.selectedVersion === "free" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleVersion("free")}>
                          Free
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === "premium_button" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""}`}>
                        <div className="d-flex justify-content-start gap-1 m-1">
                          <Button
                            onClick={handleChooseOnlyPremiumVersion}
                            className={`${chatbotAccessDetailData?.isReSelectedPremium ? 'versionButtonClick' : 'versionButton'} ${isSelectedOnlyPremiumVersion && 'disableVersion'}`}
                          >
                            Premium
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === "url_button" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""} `}>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedURL === "yes" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleProvideURL("yes")}>
                          Provide URL
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedURL === "no" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleProvideURL("no")}>
                          I need support without sharing URL
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === BUTTON_TYPE.PROVIDE_SITE_BUTTON ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""}`}>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedAccessDetail === "yes" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleProvideSiteAccess("yes")}
                        >
                          Provide access details
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedAccessDetail === "no" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleProvideSiteAccess("no")}
                        >
                          I need support without sharing site access
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === "request_provide_data" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""}`}>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedAccessDetail === "yes" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleRequestProvideData("yes")}
                        >
                          Provide access details
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.isProvidedAccessDetail === "no" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleRequestProvideData("no")}
                        >
                          I need support without sharing site access
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {
                    message.buttons && message.buttons.buttonType === "selected-fast-support" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""}`}>
                        <Button
                          onClick={() => handleSelectedFastSupport()}
                          className={`${chatbotAccessDetailData?.selectedFastSupport ? 'versionButtonClick' : 'versionButton'}`} >Proceed with expedited support ($5)
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {/* maintain service button */}
                  {
                    message.buttons && message.buttons.buttonType === "maintain-service-button" ? (
                      <div className={`d-flex justify-content-start gap-1 m-1 ${message.sender._id == user._id ? "d-flex justify-content-end" : ""} `}>
                        <Button
                          className={`${chatbotAccessDetailData?.isSelectedMaintainService === "yes" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleSelectedMaintainServiceOption("yes")}>
                          Yes, I want to use the monthly maintenance service
                        </Button>
                        <Button
                          className={`${chatbotAccessDetailData?.isSelectedMaintainService === "no" ? 'versionButtonClick' : ''}`}
                          onClick={() => handleSelectedMaintainServiceOption("no")}>
                          No i dont
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  <div className="d-flex justify-content-end">
                    {task.unread.map((unread) =>
                      user._id != unread.user &&
                      index == messages.length - unread.number - 1 &&
                      <UserRead key={unread?.user} image={users[unread.user]} />
                    )}
                  </div>
                </div>
              );
            })
        }
        <div ref={messagesEndRef}></div>
        <ProvideAccessDetail show={isShowProvideSite} setShow={setIsShowProvideSite} isFirstTask={task.isFirstTask} />
        <ProvideURLForm show={isShowProvideURLModal} setShow={setIsShowProvideURLModal} />
        {/* <div className="go-to-bottom">
        <button className="go-to-bottom-button" onClick={scrollToBottom}>
          <i className="bi bi-arrow-down-circle"></i>
        </button>
      </div> */}
      </div>
    </div>
  );
}

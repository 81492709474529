import React, { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteAllTasks } from '../redux/taskSlice';
import { deleteCurrentTask } from '../redux/currentSlice';
import { removeUserFromLocalStorage } from '../utils/localStorage';
import { SocketContext } from '../context/socket';
import '../assets/css/TopbarMobile.css'
import '../assets/css/Tasks.css'

const TopbarMobile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socket = useContext(SocketContext);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null); // Ref for the dropdown container

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false); // Close the dropdown if the click is outside
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Cleanup event listener on component unmount
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const logout = () => {
        socket.emit("logout");
        dispatch(deleteAllTasks());
        dispatch(deleteCurrentTask());
        removeUserFromLocalStorage();
        navigate("/");
    };

    const handleSelectedMenu = (link) => {
        setIsOpen(false);
        navigate(link)
    }
    return (
        <div
            className="d-flex justify-content-between "
            style={{ backgroundColor: '#F4F4FA', padding: '20px' }}
        >
            <div ref={dropdownRef} className="menu-container">
                {/* <img className="logo" src={Logo} /> */}
                <div className="hamburger-menu" onClick={() => { setIsOpen(!isOpen) }}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                {/* Dropdown Menu */}
                {isOpen && (
                    <div className="dropdown-menu-mobile">
                        <ul>
                            <li onClick={() => handleSelectedMenu('/chat')} style={{color: '#6E777D'}}>
                                <i className="bi bi-chat-dots-fill"></i>
                                Chat
                            </li>
                            <li onClick={() => handleSelectedMenu('/billing')} style={{color: '#6E777D'}}>
                                <i className="bi bi-currency-dollar"></i>
                                Billing
                            </li>
                            <li onClick={() => handleSelectedMenu('/manage')} style={{color: '#6E777D'}}>
                                <i className="bi bi-pc-display"></i>
                                Manage
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <button className="logout-mobile" onClick={logout}>
                <i class="bi bi-box-arrow-right"></i>
            </button>
        </div>
    )
}

export default TopbarMobile
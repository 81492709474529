import React, { useState, useEffect } from "react";
import api from "../../utils/axios";
import { API_URL, CURRENCY } from "../../constants/constants";
import "../../assets/css/ClientBalance.css";
import { useTranslation } from "react-i18next";
import { FormCheck } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export default function ClientBalance() {
  const { t } = useTranslation();
  const [clientsBalance, setClientsBalance] = useState([]);

  useEffect(() => {
    const getClientsBalance = async () => {
      await api
        .get(`${API_URL}/api/v1/user/clientbalance`)
        .then((res) => {
          const data = res.data;
          setClientsBalance([...data]);
        })
        .catch((err) => console.log(err));
    };

    getClientsBalance();
  }, []);

  const onEnableProject = async (event, id) => {
    await api
      .post(`${API_URL}/api/v1/user/project`, { userId: id, isProjectEnable: event.target.checked })
      .then((res) => {
        const data = res.data;
        setClientsBalance([...clientsBalance.map(item => item._id === data._id ? { ...item, isprojectenable: data.isprojectenable } : item)]);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {
        !isMobile ? <div className="clients-balance">
          <div className="title">{t("customer_balances")}</div>
          <div className="mt-3">
            {clientsBalance.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>{t("avatar")}</th>
                    <th>{t("customer_name")}</th>
                    <th>{t("balance")}</th>
                    <th>{t("action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {clientsBalance.map((client, index) => (
                    <tr key={index}>
                      <td>
                        <img src={client.avatar} className="avatar" />
                      </td>
                      <td>{client.username}</td>
                      <td>
                        {client.currency === CURRENCY.USD ? "$" : "€"}{" "}
                        {client.balance.toFixed(2)}
                      </td>
                      <td>
                        <FormCheck
                          id="switchEnabled"
                          type="switch"
                          checked={client.isprojectenable ?? false}
                          onChange={(event) => onEnableProject(event, client._id)}
                          label={t('enable_project')}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
          :
          <div className="clients-balance">
            <div className="title">{t("customer_balances")}</div>
            <div className="mt-3">
              {clientsBalance.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      {/* <th>{t("avatar")}</th> */}
                      <th>{t("customer_name")}</th>
                      <th>{t("balance")}</th>
                      {/* <th>{t("action")}</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {clientsBalance.map((client, index) => (
                      <tr key={index}>
                        {/* <td>
                          <img src={client.avatar} className="avatar" />
                        </td> */}
                        <td>{client.username}</td>
                        <td>
                          {client.currency === CURRENCY.USD ? "$" : "€"}{" "}
                          {client.balance.toFixed(2)}
                        </td>
                        {/* <td>
                          <FormCheck
                            id="switchEnabled"
                            type="switch"
                            checked={client.isprojectenable ?? false}
                            onChange={(event) => onEnableProject(event, client._id)}
                            label={t('enable_project')}
                          />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
      }
    </>
  );
}

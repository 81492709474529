import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import OnlineUser from "./OnlineUser";
import ExtraService from "./ExtraService";
import Feedback from "./Feedback";
import "../../assets/css/Manage.css";
import TypeUsers from "./TypeUsers";
import { API_URL, CURRENCY, ROLE } from "../../constants/constants";
import api from "../../utils/axios";
import { getUser } from "../../redux/userSlice";
import { isMobile } from "react-device-detect";

const TAB = {
  ONLINE_USERS: 0,
  EXTRA_SERVICE: 1,
  FEEDBACK: 2,
  TYPE_USERS: 3,
  TRACKING_CLICKED: 4,
};

export default function Manage() {
  const t = useTranslation();
  const [selectedTab, setSelectedTab] = useState(TAB.ONLINE_USERS);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUsers = async () => {
      await api
        .get(`${API_URL}/api/v1/user/clients-type`)
        .then((res) => {
          dispatch(getUser(res.data));

        })
        .catch((err) => {
        })
        .finally()
    };

    getUsers();
  }, []);

  return (
    <div className="manage d-flex jusify-content-start flex-column" style={{ padding: `${isMobile ? '0' : '50px'}` }}>
      <div className="title" style={{marginLeft: isMobile ? '20px' : ''}}>MANAGE</div>
      <div className="tab" style={{marginTop: isMobile ? '20px' : ''}}>
        <ul>
          <li
            className={selectedTab == TAB.ONLINE_USERS ? "active" : ""}
            onClick={() => setSelectedTab(TAB.ONLINE_USERS)}
          >
            Online Users
          </li>
          <li
            className={selectedTab == TAB.EXTRA_SERVICE ? "active" : ""}
            onClick={() => setSelectedTab(TAB.EXTRA_SERVICE)}
          >
            Extra Service
          </li>
          <li
            className={selectedTab == TAB.FEEDBACK ? "active" : ""}
            onClick={() => setSelectedTab(TAB.FEEDBACK)}
          >
            Feedback
          </li>
        </ul>
      </div>
      <div className="flex-auto" style={{ overflowX: "hidden" }}>
        {selectedTab == TAB.ONLINE_USERS && <OnlineUser />}
        {selectedTab == TAB.EXTRA_SERVICE && <ExtraService />}
        {selectedTab == TAB.FEEDBACK && <Feedback />}
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SocketContext } from "../context/socket";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import Upload from "rc-upload";
import { toast } from "react-toastify";
import {
  addUserToLocalStorage,
  removeUserFromLocalStorage,
  getUserFromLocalStorage
} from "../utils/localStorage";
import { deleteAllTasks } from "../redux/taskSlice";
import { deleteCurrentTask } from "../redux/currentSlice";
import { showGiftModal, fetchSwitchStatus, updateSwitchStatus } from "../redux/loginSlice"
import {
  API_URL,
  CURRENCY,
  LANGUAGE,
  ROLE,
  STANDARD_AVATAR_URL,
} from "../constants/constants";
import { login } from "../redux/loginSlice";
import "../assets/css/Sidebar.css";
import "../assets/css/SidebarMini.css";
import api from "../utils/axios";
import Logo from "../assets/images/horizontal-logo-wp-sofa.svg";
import LogoSofa from "../assets/images/sofa.svg";
import { useTranslation } from "react-i18next";
import S3 from "react-aws-s3";
import { supportedLanguages } from "../constants/languages.js";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function Sidebar() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [pencilShow, setPencilShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const socket = useContext(SocketContext);
  const name = useSelector((state) => state.login.name);
  const isAdminOnline = useSelector((state) => state.login.isAdminOnline);
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState({});
  const tasks = useSelector((state) => state.task);
  const [unread, setUnread] = useState(0);
  const [miniMode, setMiniMode] = useState(true);
  const [language, setLanguage] = useState(0);
  const [translateLanguage, setTranslateLanguage] = useState("en");

  const [tab, setTab] = useState("chat");
  const sidebarTab = useSelector((state) => state.login.sidebarTab);

  const userData = getUserFromLocalStorage();
  const balance = userData.balance;

  useEffect(() => {
    const user = getUserFromLocalStorage();
    const isNewUser = user.isnewuser;
    if (isNewUser) setTab("billing");
  }, [sidebarTab])

  const languageList = [
    {
      language: t("english"),
      slanguage: "EN",
      value: LANGUAGE.EN,
      code: "en",
    },
    {
      language: t("german"),
      slanguage: "DE",
      value: LANGUAGE.DE,
      code: "de",
    },
    {
      language: "Spanish",
      slanguage: "ES",
      value: LANGUAGE.ES,
      code: "es",
    },
  ];

  const config_avatar = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: "avatar",
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS,
    secretAccessKey: process.env.REACT_APP_SECRET,
  };

  useEffect(() => {
    const getSupportedLanguages = async () => {
      await api
        .get(`${API_URL}/api/v1/message/supportedlanguage`)
        .then((res) => {
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    };
    // getSupportedLanguages();
  }, []);

  useEffect(() => {
    const user = getUserFromLocalStorage();
    if (typeof user.language !== "undefined") setLanguage(user.language);
    else setLanguage(0);

    if (typeof user.translatelanguage !== "undefined")
      setTranslateLanguage(user.translatelanguage);
    else setLanguage("en");

    setMiniMode(user.minisidebar);
  }, []);

  useEffect(() => {
    let code;
    if (language === "en") {
      let user = getUserFromLocalStorage();
      user.language = LANGUAGE.EN;
      addUserToLocalStorage(user);
      setLanguage(LANGUAGE.EN);
      code = languageList[LANGUAGE.EN].code;
    } else code = languageList[language].code;
    i18n.changeLanguage(code);
  }, [language]);

  // Calculate Unread Number
  useEffect(() => {
    const getData = async () => {
      const tempUser = getUserFromLocalStorage();
      if (!tempUser) navigate("/");
      else {
        setUser(tempUser);
        setAvatar(tempUser.avatar);
        dispatch(login(tempUser));
        // Get user information again from id  for checking token valid.
        await api
          .get(`${API_URL}/api/v1/user/`)
          .then((res) => { })
          .catch((err) => {
            if (err.response.status == 401) {
              navigate("/");
            }
            const errors = err.response.data.errors;
            errors.forEach((error) => toast.error(error.msg));
          });
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const tempUser = getUserFromLocalStorage();
    if (!tempUser) navigate("/");
    else {
      let unread = 0;
      for (let i = 0; i < tasks.length; i++) {
        for (let j = 0; j < tasks[i].unread.length; j++)
          if (tasks[i].unread[j].user == tempUser._id)
            unread += tasks[i].unread[j].number;
      }
      setUnread(unread);
    }
  }, [tasks]);

  useEffect(() => {
    const pathname = location.pathname;

    switch (pathname) {
      case "/chat":
        setTab("chat");
        break;
      case "/billing":
        setTab("billing");
        break;
      default:
        setTab("");
    }
  }, []);

  // Log out
  const logOut = () => {
    socket.emit("logout", { userId: user._id });
    socket.off("setup", user._id);
    socket.disconnect();
    dispatch(deleteAllTasks());
    dispatch(deleteCurrentTask());
    removeUserFromLocalStorage();
    localStorage.removeItem("BillingInfo");
    navigate("/");
  };

  const handleUploadClick = (e) => {
    e.preventDefault();
  };

  const handleUploadProgress = (e) => { };

  const handleUploadSuccess = async (body, file) => {
    api
      .post(`${API_URL}/api/v1/user/avatar`, { avatar: body })
      .then((res) => {
        let user = getUserFromLocalStorage();
        user.avatar = body;
        addUserToLocalStorage(user);
        setAvatar(body);
        toast.success("Changed Successfully");
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const handleUploadError = (e, body) => {
    toast.error(body.error);
  };

  const handleChange = (e) => {
    const fileObject = e.target.files[0];

    const uniqueSuffix = Date.now() + "_" + Math.round(Math.random() * 1e9);
    const filename = uniqueSuffix + "_" + fileObject.name;

    const ReactS3Client = new S3(config_avatar);
    // the name of the file uploaded is used to upload it to S3
    ReactS3Client.uploadFile(fileObject, filename)
      .then((data) => {
        changeUserAvatar(data.location);
      })
      .catch((err) => {
        toast.error("Uploading Failed!");
      });
  };

  const changeUserAvatar = async (userAvatar) => {
    api
      .post(`${API_URL}/api/v1/user/avatar`, { avatar: userAvatar })
      .then((res) => {
        let user = getUserFromLocalStorage();
        user.avatar = userAvatar;
        addUserToLocalStorage(user);
        setAvatar(userAvatar);
        toast.success("Changed Successfully");
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const changeUserLanguage = async (e) => {
    const language = e.target.value;

    api
      .post(`${API_URL}/api/v1/user/language`, { language })
      .then((res) => {
        let user = getUserFromLocalStorage();
        user.language = language;
        addUserToLocalStorage(user);
        setLanguage(language);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const changeUserTranslateLanguage = async (e) => {
    const translateLanguage = e.target.value;

    api
      .post(`${API_URL}/api/v1/user/translatelanguage`, {
        translatelanguage: translateLanguage,
      })
      .then((res) => {
        let user = getUserFromLocalStorage();
        user.translatelanguage = translateLanguage;
        addUserToLocalStorage(user);
        setTranslateLanguage(translateLanguage);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const changeUserMinisidebar = async () => {
    api
      .post(`${API_URL}/api/v1/user/minisidebar`, { minisidebar: !miniMode })
      .then((res) => {
        let user = getUserFromLocalStorage();
        user.minisidebar = !miniMode;
        addUserToLocalStorage(user);

        setMiniMode(!miniMode);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  useEffect(() => {
    dispatch(fetchSwitchStatus())
  }, [dispatch])

  const handleChangeStatusAdminOnline = async () => {
    dispatch(updateSwitchStatus(!isAdminOnline))
  }

  return (
    <>
      {miniMode ? (
        <div className="sidebar-mini">
          <div className="text-center">
            <div className="image-container">
              <button
                className="mini-mode-button w-100"
                onClick={() => changeUserMinisidebar()}
              >
                <i className="bi bi-chevron-double-right"></i>
              </button>
              <img className="mt-5" src={LogoSofa} width="80%" />
              {
                (user.role === ROLE.ADMIN && user._id === "6706508a7de20537dd633b1f") && <label class="switch-small">
                  <input type="checkbox" id="togBtn" checked={isAdminOnline ?? false} defaultChecked={isAdminOnline} onChange={handleChangeStatusAdminOnline} />
                  <span className="slider-small"></span>
                </label>
              }
              <div className="position-relative" id="img_profile">
                {/* <Upload
                  name="image"
                  onClick={handleUploadClick}
                  onProgress={handleUploadProgress}
                  onSuccess={handleUploadSuccess}
                  onError={handleUploadError}
                  action={`${API_URL}/api/v1/upload/cloudinary/avatar`}
                  method="post"
                >
                  <img
                    className="rounded-circle user-avatar position-relative"
                    src={avatar}
                  />
                </Upload> */}
                <label htmlFor="avatar-upload-button">
                  <div
                    className="position-relative"
                    onMouseOver={(e) => {
                      setPencilShow(true);
                    }}
                    onMouseOut={(e) => {
                      setPencilShow(false);
                    }}
                  >
                    <img
                      className="rounded-circle user-avatar position-relative"
                      src={avatar}
                    />
                    {(avatar == STANDARD_AVATAR_URL || pencilShow) && (
                      <div className="user-avatar-pencil">
                        <i className="bi bi-pencil"></i>
                      </div>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="avatar-upload-button"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="mt-5 text-center">
            <ul>
              <li
                className={`${tab == "chat" ? "active-sidebar-tab" : "sidebar-tab"
                  } d-flex justify-content-center align-items-center`}
                onClick={(e) => {
                  setTab("chat");
                  navigate("/chat");
                }}
              >
                <i className="bi bi-chat-dots-fill"></i>
                {unread > 0 ? (
                  <div className="sidebar-unread">{unread}</div>
                ) : (
                  <></>
                )}
              </li>
              {user.role !== ROLE.DEV && (
                <li
                  className={
                    tab == "billing" ? "active-sidebar-tab" : "sidebar-tab"
                  }
                  onClick={(e) => {
                    setTab("billing");
                    navigate("/billing");
                  }}
                >
                  <i className="bi bi-currency-dollar"></i>
                </li>
              )}
              {user.role === ROLE.ADMIN && (
                <li
                  className={
                    tab == "manage" ? "active-sidebar-tab" : "sidebar-tab"
                  }
                  onClick={(e) => {
                    setTab("manage");
                    navigate("/manage");
                  }}
                >
                  <i className="bi bi-pc-display"></i>
                </li>
              )}
            </ul>
            {
              user.role === ROLE.CLIENT && <div onClick={() => dispatch(showGiftModal())}><i className="gift-icon bi bi-gift-fill"></i></div>
            }
            {user.role !== ROLE.DEV && (
              <div style={{ marginTop: "35px" }} className="d-flex justify-content-center">
                <div className={balance < 0 ? "voucher-nagative" : "voucher"}>
                  {user.currency == CURRENCY.USD ? "$" : "€"}{" "}
                  {balance && balance.toFixed(2)}
                </div>
              </div>
            )}
            <div className="text-center mt-5">
              <select
                className="language-select"
                value={language}
                onChange={changeUserLanguage}
              >
                {languageList.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.slanguage}
                    </option>
                  );
                })}
              </select>
            </div>
            {user.role !== ROLE.CLIENT && (
              <div className="text-center mt-5">
                <select
                  className="language-select"
                  value={translateLanguage}
                  onChange={changeUserTranslateLanguage}
                >
                  {supportedLanguages.map((item, index) => {
                    return (
                      <option key={index} value={item.code}>
                        {item.code.toUpperCase()}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <div className="logout" style={{ listStyle: "none" }}>
              <li className="tab" onClick={logOut}>
                <i className="bi bi-power"></i>
              </li>
            </div>
          </div>
        </div>
      ) : (
        <div className="sidebar">
          <div className="text-center">
            <div className="image-container">
              <button
                className="mini-mode-button w-100"
                onClick={() => changeUserMinisidebar()}
              >
                <i className="bi bi-chevron-double-left"></i>
              </button>
              <img className="mt-5" src={Logo} width="100%" />
              {
                (user.role === ROLE.ADMIN && user._id === "6706508a7de20537dd633b1f") && <label class="switch">
                  <input type="checkbox" id="togBtn" checked={isAdminOnline ?? false} defaultChecked={isAdminOnline} onChange={handleChangeStatusAdminOnline} />
                  <div class="slider round">
                    <span class="on">Online</span>
                    <span class="off">Offline</span>
                  </div>
                </label>
              }
              <div className="position-relative">
                {/* <Upload
                  name="image"
                  onClick={handleUploadClick}
                  onProgress={handleUploadProgress}
                  onSuccess={handleUploadSuccess}
                  onError={handleUploadError}
                  action={`${API_URL}/api/v1/upload/cloudinary/avatar`}
                  method="post"
                >
                  <img
                    className="rounded-circle user-avatar position-relative"
                    src={avatar}
                  />
                </Upload> */}
                <label htmlFor="avatar-upload-button" id="img_profile">
                  <div
                    className="position-relative"
                    onMouseOver={(e) => {
                      setPencilShow(true);
                    }}
                    onMouseOut={(e) => {
                      setPencilShow(false);
                    }}
                  >
                    <img
                      className="rounded-circle user-avatar position-relative"
                      src={avatar}
                    />
                    {(avatar == STANDARD_AVATAR_URL || pencilShow) && (
                      <div className="user-avatar-pencil">
                        <i className="bi bi-pencil"></i>
                      </div>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="avatar-upload-button"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </div>
            </div>
            <h5 className="mt-3">{user.name || user.username}</h5>
          </div>
          <div className="mt-5 text-center">
            <ul>
              <li
                className={`${tab == "chat" ? "active-sidebar-tab" : "sidebar-tab"
                  } d-flex justify-content-center align-items-center`}
                onClick={(e) => {
                  setTab("chat");
                  navigate("/chat");
                }}
              >
                <i className="bi bi-chat-dots-fill"></i>
                {t("chat")}
                {unread > 0 ? (
                  <div className="sidebar-unread">{unread}</div>
                ) : (
                  <></>
                )}
              </li>
              {user.role !== ROLE.DEV && (
                <li
                  className={
                    tab == "billing" ? "active-sidebar-tab" : "sidebar-tab"
                  }
                  onClick={(e) => {
                    setTab("billing");
                    navigate("/billing");
                  }}
                >
                  <i className="bi bi-currency-dollar"></i>
                  {t("billing")}
                </li>
              )}

              {user.role === ROLE.ADMIN && (
                <li
                  className={
                    tab == "manage" ? "active-sidebar-tab" : "sidebar-tab"
                  }
                  onClick={(e) => {
                    setTab("manage");
                    navigate("/manage");
                  }}
                >
                  <i className="bi bi-pc-display"></i>
                  {t("manage")}
                </li>
              )}
              {user.role === ROLE.ADMIN && (
                <li
                  className={
                    tab == "gift-code" ? "active-sidebar-tab" : "sidebar-tab"
                  }
                  onClick={(e) => {
                    setTab("gift-code");
                    navigate("/gift-code");
                  }}
                >
                  {/* <i className="bi bi-pc-display"></i> */}
                  <i className="bi bi-gift-fill"></i> GIFT CODE
                </li>
              )}
            </ul>
            {
              user.role === ROLE.CLIENT && <div style={{ marginBottom: "25px", display: "flex", justifyContent: "center", alignItems: "center", gap: "7px" }} onClick={() => dispatch(showGiftModal())}><i className="gift-icons bi bi-gift-fill"></i></div>
            }
            {user.role !== ROLE.DEV && (
              <div className="text-center">
                <div className="your-balance mb-2">{t("your_balance")}</div>
                <div className="d-flex justify-content-center">
                  <div className={balance < 0 ? "voucher-nagative" : "voucher"}>
                    {user.currency == CURRENCY.USD ? "$" : "€"}{" "}
                    {balance && balance.toFixed(2)}
                  </div>
                </div>
              </div>
            )}
            <div className="text-center mt-2">
              <div className="your-balance mb-2">{t("your_language")}</div>
              <select
                className="language-select"
                value={language}
                onChange={changeUserLanguage}
              >
                {languageList.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.language}
                    </option>
                  );
                })}
              </select>
            </div>

            {user.role !== ROLE.CLIENT && (
              <div className="text-center mt-2">
                <div className="your-balance mb-2">
                  {t("translate_language")}
                </div>
                <select
                  className="language-select"
                  value={translateLanguage}
                  onChange={changeUserTranslateLanguage}
                >
                  {supportedLanguages.map((item, index) => {
                    return (
                      <option key={index} value={item.code}>
                        {item.language}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <div className="logout" style={{ listStyle: "none" }}>
              <li className="tab" onClick={logOut}>
                <i className="bi bi-power"></i>
                {t("logout")}
              </li>
            </div>
          </div>
        </div >
      )
      }
    </>

  )
}

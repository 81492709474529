import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import { SocketContext, socket } from "./context/socket";
import store from "./store";
import Chat from "./components/Chat/Index";
import Login from "./components/Login/Index";
import RegisterGiftcardClient from "./components/Register/RegisterGiftcardClient";
import RegisterDev from "./components/Register/RegisterDev";
import Billing from "./components/Billing/Index";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./components/Sidebar";
import NotFound from "./components/NotFound";
import PaymentSuccess from "./components/PaymentSuccess";
import Manage from "./components/Manage";
import { useEffect } from "react";
import OneSignal from "react-onesignal";
import { getUserFromLocalStorage } from "./utils/localStorage";
import PrivacyPolicy from "./components/PrivacyPolicy"
import Terms from "./components/TermsAndCondition";
import Imprint from './components/HomePage/Imprint';
import TermsOfAgreement from './components/HomePage/TermsOfAgreement';
import ResetPassword from './components/ResetPassword';
import RegisterAgencyClient from './components/Register/RegisterAgencyClient';
import GiftModal from './components/GiftModal';
import PayForReferrerModal from './components/GiftCode/PayForReferrerModal';
import { hideGiftModal, hidePayForReferrerModal, showGiftModal } from './redux/loginSlice';
import TasksWithGiftCode from './components/GiftCode/TasksWithGiftCode';
import {
  browserName,
  osName,
  deviceType,
  isMobile,
  isTablet,
} from "react-device-detect";
import TopbarMobile from './components/TopbarMobile';
// import WithSidebar from './components/WithSidebar';
const Homepage = lazy(() => import('./components/HomePage'));

const WithSidebar = () => {
  const dispatch = useDispatch();
  const { isShowGiftModal, isShowPayForReferrerModal } = useSelector((state) => state.login);

  const onHideGiftModal = () => {
    dispatch(hideGiftModal());
  }

  const onHidePayForReferrerModal = () => {
    dispatch(hidePayForReferrerModal());
  }

  const onShowGiftModal = () => {
    dispatch(showGiftModal())
  }

  return (
    // <div className="row height-100">
    //   <div className="col-2">
    //     <Sidebar />
    //   </div>
    //   <div className="col-10 right-sidebar" style={{ position: "relative" }}>
    //     <Outlet />
    //   </div>
    // </div>
    <>
      {
        !isMobile ? <div className="d-flex height-100 w-100 justify-content-start">
          {/* {
        !isMobile ? <div>
          <Sidebar />
        </div> : <TopbarMobile />
      } */}
          <Sidebar />
          <div className="w-100 right-sidebar position-relative">
            <Outlet />
          </div>
          <GiftModal show={isShowGiftModal} onHide={onHideGiftModal} onShow={onShowGiftModal} />
          <PayForReferrerModal show={isShowPayForReferrerModal} onHide={onHidePayForReferrerModal} />
        </div> : <div>
          <TopbarMobile />
          <div className="w-100 right-sidebar position-relative">
            <Outlet />
          </div>
          <GiftModal show={isShowGiftModal} onHide={onHideGiftModal} onShow={onShowGiftModal} />
          <PayForReferrerModal show={isShowPayForReferrerModal} onHide={onHidePayForReferrerModal} />
        </div>
      }
    </>
  );
};

const WithoutSidebar = () => {
  return <Outlet />;
};

function App() {
  const user = getUserFromLocalStorage();
  // 61a6ec6b-1dcc-429b-9f24-812ed86ec4d1
  // 19b8fb16-25a6-485e-860f-b65554337e6f
  useEffect(() => {
    OneSignal.init({
      appId: "61a6ec6b-1dcc-429b-9f24-812ed86ec4d1",
      allowLocalhostAsSecureOrigin: true,
    }).then(() => {
      OneSignal.showSlidedownPrompt().then(() => {
        // do other stuff
      });
    });
    //
    // if ("Notification" in window) {
    //   if(Notification.permission === 'granted'){
    //     return
    //   }
    //   if (Notification.permission !== "denied") {
    //     Notification.requestPermission().then((permission) => {
    //       console.log("permission", permission);
    //     })
    //   }
    // }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (user?._id)
        socket.emit("is-online", `${user._id}>send`);
    }, 10000);
    return () => {
      socket.removeListener("is-online")
      clearInterval(interval)
    };
  }, [user?._id])

  return (
    <Suspense fallback={<></>}>
      <SocketContext.Provider value={socket}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route element={<WithSidebar />}>
                <Route path="/chat" element={<Chat />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/paymentsuccess" element={<PaymentSuccess />} />
                <Route path="/manage" element={<Manage />} />
                <Route path="/gift-code" element={<TasksWithGiftCode />} />
              </Route>
              <Route element={<WithoutSidebar />}>
                <Route path="/" element={<Homepage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register-wp-giftcard" element={<RegisterGiftcardClient />} />
                <Route path="/register-wp-giftcard/:code" element={<RegisterGiftcardClient />} />
                <Route path="/register-agency" element={<RegisterAgencyClient />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/register/dev" element={<RegisterDev />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/terms-of-agreement" element={<TermsOfAgreement />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
            <ToastContainer autoClose={1500} limit={3} />
          </BrowserRouter>
        </Provider>
      </SocketContext.Provider>
    </Suspense>
  );
}

export default App;

import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export const currentSlice = createSlice({
  name: "current",
  initialState: {},
  reducers: {
    deleteCurrentTask: (state) => {
      for (const key in state) {
        delete state[key];
      }
    },
    setCurrentTask: (state, action) => {
      const task = action.payload;
      state.client = task.client;
      state._id = task._id;
      state.taskname = task.taskname;
      state.cost = task.cost;
      state.duedate = task.duedate;
      state.priority = task.priority;
      state.status = task.status;
      state.workeffort = task.workeffort;
      state.unread = task.unread;
      state.users = task.users;
      state.rating = task.rating;
      state.isFirstTask = task.isFirstTask;
      state.isPaidFastSupport = task.isPaidFastSupport;
      state.messageDraft = task.messageDraft;
      state.filesDraft = task.filesDraft;
    },
  },
});

export const { deleteCurrentTask, setCurrentTask } = currentSlice.actions;

export default currentSlice.reducer;

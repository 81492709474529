import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/Deposit.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import api from "../../utils/axios";
import { API_URL, PUBLIC_KEY } from "../../constants/constants";
import CheckoutForm from "./CheckoutForm";
import DepositMan from "../../assets/images/deposit_man.png";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { CURRENCY } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { MdOutlineClose } from "react-icons/md";
import { FaCreditCard, FaPaypal } from "react-icons/fa";
import SofortIcon from "../../assets/images/sofort.svg";
import EPSIcon from "../../assets/images/eps.svg";
import PaypalCheckoutForm from "./PaypalCheckoutForm";
import { toast } from "react-toastify";
import BillingInfo from "./BillingInfo";
import {
  isMobile,
} from "react-device-detect";

const stripePromise = loadStripe(PUBLIC_KEY);

export default function Deposit({
  amount,
  modalShow,
  setModalShow,
  setConfettiStatus,
}) {
  const { t } = useTranslation();
  const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [agree, setAgree] = useState(false);
  const [depositPromoShow, setDepositPromoShow] = useState(false);
  const [user, setUser] = useState({});
  const [savedCard, setSavedCard] = useState([]);
  const [tab, setTab] = useState(0);
  const [billingInfo, setBillingInfo] = useState("");
  const [isShowDepositModal, setIsShowDepositModal] = useState(false);

  const ref = useRef(null);

  const currency = [
    {
      name: "US Dollar",
      symbol: "$",
      currency: "usd",
    },
    {
      name: "Euro",
      symbol: "€",
      currency: "eur",
    },
  ];

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const [depositAmount, setDepositAmount] = useState(amount ? amount : 1);

  // check is first pay
  // if is first pay: show depositPromoShow and else
  useEffect(() => {
    api.get(`${API_URL}/api/v1/payment/all`)
      .then((res) => {
        if (res.data.length !== 0) setDepositPromoShow(false);
        else setDepositPromoShow(true);
      })
  }, [])

  useEffect(() => {
    const tempUser = getUserFromLocalStorage();
    setUser(tempUser);

    api
      .post(`${API_URL}/api/v1/payment/saved-card-list`, {
        userId: tempUser.email,
      })
      .then((res) => {
        const clientSavedCard = res?.data?.paymentMethods?.data;
        setSavedCard(clientSavedCard ? clientSavedCard : []);
      })
      .catch((e) => console.log(e));

    if (tempUser.currency == CURRENCY.USD) setSelectedCurrency(0);
    else setSelectedCurrency(1);
  }, []);

  const onDepositAmount = (e) => {
    const value = Math.max(1, e.target.value);
    setDepositAmount(value);
  };

  const onPay = () => {
    if (!billingInfo || billingInfo?.name === "" || billingInfo?.country === "" || (billingInfo?.is_eu && billingInfo?.vat === "" && billingInfo?.isVATable === "yes") || (billingInfo?.is_eu && billingInfo?.isVATable === "")) {
      toast.warning("Please provide the required information to proceed with the payment");
      setIsShowDepositModal(true);
      return;
    }
    const tempUser = getUserFromLocalStorage();
    api
      .post(`${API_URL}/api/v1/payment/create-payment-intent`, {
        amount: amount ? amount : depositAmount,
        currency: currency[selectedCurrency].currency,
        email: tempUser.email ? tempUser.email : user.email,
      })
      .then((res) => {
        const clientSecret = res.data.clientSecret;
        setClientSecret(clientSecret);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    api.get(`${API_URL}/api/v1/billing`)
      .then((res) => {
        setBillingInfo(res.data);
      })
  }, [isShowDepositModal])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsShowDepositModal(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="deposit">
      {
        isShowDepositModal && <div className="pay-for-referrer-modal d-flex justify-content-center align-items-center">
          <div ref={ref} className="pay-for-referrer-modal-model" style={{ overflowY: "scroll", maxHeight: "90vh" }}>
            <BillingInfo setIsShowDepositModal={setIsShowDepositModal} />
          </div>
        </div>
      }
      {modalShow ? (
        <>
          <div className="d-flex justify-content-between align-align-items-center">
            <div className="title">{t("deposit")}</div>
            <div className="close" onClick={() => setModalShow(false)}>
              <MdOutlineClose />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="title">{t("deposit")}</div>
        </>
      )}

      <h6 className="mb-4">{t("please_fill_out")}</h6>
      {!clientSecret && (
        <div className="row">
          <div className="card col-4">
            <div className="currency d-flex justify-content-between align-items-center">
              {t("deposit_currency")}
              <select
                disabled="true"
                className="deposit-currency"
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
              >
                {currency.map((item, index) => (
                  <option key={index} value={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {t("deposit_amount")}
              <div className="d-flex align-items-center position-relative">
                <input
                  className="deposit-number"
                  type="number"
                  value={depositAmount}
                  onChange={onDepositAmount}
                />
                {/* {
                  amount ? <p style={{ backgroundColor: '#207F83', color: '#FFF', padding: '7px 10px', borderRadius: '7px' }}>{currency[selectedCurrency].symbol} {amount}</p> : <input
                    className="deposit-number"
                    type="number"
                    value={depositAmount}
                    onChange={onDepositAmount}
                  />
                }
                {
                  amount ? '' : <div className="currency-symbol">
                    {currency[selectedCurrency].symbol}
                  </div>
                } */}
              </div>
            </div>
            <hr />
            {
              !isMobile && <div className="d-flex agree-select">
                <input
                  id="agree"
                  checked={agree ? true : false}
                  onClick={() => setAgree(!agree)}
                  type="checkbox"
                  className="check"
                  defaultChecked
                />
                <div onClick={() => setAgree(!agree)}>
                  {t("I_have_read")}{" "}
                  <a
                    href="https://codemenschen.at/Allgemeine_Geschaeftsbedingungen.pdf"
                    target="new"
                  >
                    {t("terms_and_conditions")}
                  </a>
                </div>
              </div>
            }
            {
              !isMobile && <button
                className="pay w-100"
                onClick={() => {
                  onPay();
                }}
                disabled={agree ? false : true}
                style={{
                  width: '100%'
                }}
              >
                {t("confirm")} {currency[selectedCurrency].symbol}
                {(depositAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </button>
            }
          </div>
          {
            isMobile && <div className="d-flex agree-select">
              <input
                id="agree"
                checked={agree ? true : false}
                onClick={() => setAgree(!agree)}
                type="checkbox"
                className="check"
                defaultChecked
              />
              <div onClick={() => setAgree(!agree)}>
                {t("I_have_read")}{" "}
                <a
                  href="https://codemenschen.at/Allgemeine_Geschaeftsbedingungen.pdf"
                  target="new"
                >
                  {t("terms_and_conditions")}
                </a>
              </div>
            </div>
          }
          {
            isMobile && <button
              className="pay w-100"
              onClick={() => {
                onPay();
              }}
              disabled={agree ? false : true}
              style={{
                width: '100%'
              }}
            >
              {t("confirm")} {currency[selectedCurrency].symbol}
              {(depositAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </button>
          }
          {depositPromoShow && !isMobile ? (
            <div className="deposit-promo-card position-relative col-4">
              <button
                className="close-button"
                onClick={() => setDepositPromoShow(false)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
              <div>{t("if_you_currently_make")}</div>
              <div className="d-flex align-items-baseline">
                {/* <div className="large-text">
                  {currency[selectedCurrency].symbol}1000
                </div>{" "}
                {t("you_will_get")} */}
                {t("you_will_receive")}
                <div className="large-text ms-2 me-2"> 25%</div>
                {t("bonus")}
              </div>
              <div className="d-flex align-items-baseline">
                {/* <div className="large-text">
                  {currency[selectedCurrency].symbol}150
                </div>{" "}
                {t("bonus_on_your")} */}
                {t("bonus_on_your")}
              </div>
              <img className="mt-3" src={DepositMan} />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}

      {clientSecret && (
        <>
          {
            !isMobile ? <div className="d-flex align-items-center gap-4">
              <button className={`pay ${tab === 0 ? "" : "pay-disabled"} `} onClick={() => setTab(0)}>
                <FaCreditCard size={35} ></FaCreditCard>
              </button>
              <button className={`pay ${tab === 1 ? "" : "pay-disabled"} `} onClick={() => setTab(1)}>
                <FaPaypal size={45} ></FaPaypal>
                <img src={SofortIcon} style={{ marginLeft: "10px", marginRight: "10px" }} height={45} />
                <img src={EPSIcon} style={{ marginLeft: "10px", marginRight: "10px" }} height={45} />
              </button>
            </div> : <div className="gap-4">
              <button className={`pay ${tab === 0 ? "" : "pay-disabled"} `} onClick={() => setTab(0)}>
                <FaCreditCard size={35} ></FaCreditCard>
              </button>
              <button className={`pay ${tab === 1 ? "" : "pay-disabled"} `} onClick={() => setTab(1)}>
                <FaPaypal size={45} ></FaPaypal>
                <img src={SofortIcon} style={{ marginLeft: "10px", marginRight: "10px" }} height={45} />
                <img src={EPSIcon} style={{ marginLeft: "10px", marginRight: "10px" }} height={45} />
              </button>
            </div>
          }

          {
            tab === 0 &&
            <div className={ isMobile ? 'card-mobile' : 'card'}>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  modalShow={modalShow}
                  setModalShow={setModalShow}
                  savedCard={savedCard}
                  clientSecretId={clientSecret}
                  // onPaymentIntent={onPaymentIntent}
                  setConfettiStatus={setConfettiStatus}
                  amount={`${currency[selectedCurrency].symbol}${(depositAmount)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
              </Elements>
            </div>
          }

          {tab === 1 && <PaypalCheckoutForm
            amountCurrency={`${currency[selectedCurrency].currency}`}
            amountSymbol={`${currency[selectedCurrency].symbol}`}
            amount={depositAmount}
            setModalShow={setModalShow}
            setConfettiStatus={setConfettiStatus}
          />}
        </>
      )}
    </div>
  );
}

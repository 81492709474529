import axios from 'axios';
import { validURL } from './common';

export const containsUrl = (input) => {
  // Regex to match URLs
  const urlRegex = /https?:\/\/[^\s]+/i;
  return urlRegex.test(input); // Returns true if a URL is found
};

export const containsValidUrl = (input) => {
  const words = input.split(/\s+/); // Split by whitespace
  return words.some(validURL); // Check if any word is a valid URL
};

export const getBaseURL = (input) => {
  try {
    const urlRegex = /(https?:\/\/[^\s]+)/; // Regex to match URLs
    const match = input.match(urlRegex); // Find the URL in the string

    if (match) {
      const url = new URL(match[0]); // Create a URL object to parse the URL
      return `${url.protocol}//${url.hostname}`; // Return protocol and hostname
    }

    return null; // No URL found
  } catch (error) {
    console.error("Error parsing URL:", error);
    return null;
  }
};

export const getLastElement = (input) => {
  if (!input) return null; // Handle empty input
  const parts = input.split(" ");
  return parts[parts.length - 1]; // Return the last element
};

export const fetchPageSpeedMobileData = async (url) => {
  const apiKey = 'AIzaSyBeVO9IK93vmIhLjISnKV1J3RKuqI3ZsMk';
  const baseURL = getBaseURL(url);
  const apiUrl = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(baseURL)}&key=${apiKey}&strategy=mobile`;

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error("Error fetching PageSpeed Insights data:", error);
    return null;
  }
};

export const fetchPageSpeedDesktopData = async (url) => {
  const apiKey = 'AIzaSyBeVO9IK93vmIhLjISnKV1J3RKuqI3ZsMk';
  const baseURL = getBaseURL(url);
  const apiUrl = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(baseURL)}&key=${apiKey}&strategy=desktop`;

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error("Error fetching PageSpeed Insights data:", error);
    return null;
  }
};